import { Container, Typography, Grid,Button } from '@mui/material';
import myBackground from '../svgs/blob-scene-haikei.svg';
import { Link } from 'react-router-dom';
import Navbar from '../compoments/navbar'

const heroStyles = {
  paddingTop: "64px", // set the desired padding value here
  marginTop: "64px", // set the same value as paddingTop here
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "80%",
};

const footerStyles = {
  bottom: '2rem',
  width: '100%',
  backgroundColor: 'transparent',
  padding: '20px',
};


function Homepage() {
  return (
    <div
      style={{
        backgroundImage: `url(${myBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        width: '100vw',
        overflow: 'auto'
      }}
    >
      <Navbar />
      <Container maxWidth="lg" style={heroStyles}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={7}>
            <Typography variant="h2" align="left" color="textPrimary" gutterBottom>
              Gapevine: Your One-Stop App for Podcast Sharing
            </Typography>
            <Typography variant="h5" align="left" color="textSecondary" paragraph>
              Connect your Spotify account to Grapevine to share your listening activity with friends and discover new music and podcasts. Gapevine makes it easy to discover your next favourite podacast.
            </Typography>
            <div align="left">
              <Link to='/signin' style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary" style={{ marginRight: "10px" }}>
                  Sign In
                </Button>
              </Link>
              <Link to='/signup' style={{ textDecoration: 'none' }}>
                <Button variant="outlined" color='secondary'>
                  Sign Up
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
      <footer style={footerStyles}>
          <Typography variant="body2" align="center" color="textSecondary">
            Disclaimer: This app uses the Spotify API but is not endorsed or certified by Spotify. All Spotify logos and trademarks displayed in this app are the property of Spotify.
          </Typography>
        </footer>
    </div>
  );
}

export default Homepage;
