import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  TextField,
  IconButton,
  Button,
  Skeleton,
} from '@mui/material';
import { collection, doc, getDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import ClearIcon from '@mui/icons-material/Clear';

const PostCard = () => {
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userComment, setUserComment] = useState('');
  const [user] = useAuthState(auth);
  const [userAttributes, setUserAttributes] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userCollection = collection(firestore, 'Users');
          const userRef = doc(userCollection, user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserAttributes(userData);
          } else {
            console.log('User data not found');
          }
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (user && selectedEpisode && userComment) {
      try {
        // Save the post to Firestore collection 'posts'
        const newPost = {
          episodeId: selectedEpisode.id,
          timestamp: serverTimestamp(),
          comments: [userComment],
        };

        // Save the new post document to the "posts" subcollection of the user
        await addDoc(collection(firestore, 'Users', user.uid, 'posts'), newPost);

        // Reset the form fields
        clearSelection();
        setSuccessMessage('Congratulations! Your post is live for the world to see.');

        console.log('Post saved successfully');
      } catch (error) {
        console.log('Error saving post:', error);
      }
    }
  };

  const handleSearchTermChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  
    if (term.trim() === '') {
      setSearchResults([]);
      return;
    }
  
    setLoading(true);
  
    // Simulate a 3-second delay using setTimeout
    setTimeout(async () => {
      try {
        const accessToken = userAttributes.spotifyAccessToken;
        const searchUrl = `https://api.spotify.com/v1/search?q=${encodeURIComponent(term)}&type=episode`;
  
        const response = await fetch(searchUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          const episodes = data.episodes ? data.episodes.items : [];
          setSearchResults(episodes);
        } else {
          console.log('Error searching podcasts:', response.status);
        }
      } catch (error) {
        console.log('Error searching podcasts:', error);
      }
  
      setLoading(false);
    }, 2000);
  };
  

  const handleSelectEpisode = (episode) => {
    setSelectedEpisode(episode);
    setSearchTerm(''); // Clear the search term
  };

  const clearSelection = () => {
    setSelectedEpisode('');
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleUserCommentChange = (event) => {
    setUserComment(event.target.value);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        {successMessage ? (
          <Typography variant="h6" gutterBottom>
            {successMessage}
          </Typography>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              Make a Post
            </Typography>
            <form onSubmit={handleFormSubmit}>
              {/* Podcast Search */}
              {selectedEpisode ? (
                <Card variant="outlined" style={{ marginBottom: '1rem', maxWidth: 'sm' }}>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={10}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Avatar
                              src={selectedEpisode.images[0].url}
                              alt="Podcast Thumbnail"
                              style={{ width: '60px', height: '60px' }}
                              variant="rounded"
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" style={{ overflowWrap: 'break-word' }}>
                              {selectedEpisode.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton aria-label="Clear" onClick={clearSelection}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                <>
                  <TextField
                    label="Search Podcast Episode"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    fullWidth
                    style={{ marginBottom: '1rem' }}
                  />

                  {/* Search Results */}
                  {loading ? (
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {[...Array(5)].map((_, index) => (
                        <Card
                            key={index}
                            variant="outlined"
                            style={{ cursor: 'pointer', marginBottom: '0.5rem' }}
                        >
                            <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                <Skeleton
                                    variant="rectangular"
                                    width={60}
                                    height={60}
                                    animation="wave"
                                />
                                </Grid>
                                <Grid item>
                                <Skeleton
                                    variant="text"
                                    width={200}
                                    height={24}
                                    animation="wave"
                                />
                                </Grid>
                            </Grid>
                            </CardContent>
                        </Card>
                        ))}
                    </div>
                    ) : (
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {searchResults.slice(0, 5).map((episode) => (
                        <Card
                            key={episode.id}
                            variant="outlined"
                            onClick={() => handleSelectEpisode(episode)}
                            style={{ cursor: 'pointer', marginBottom: '0.5rem' }}
                        >
                            <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                <Avatar
                                    src={episode.images[0].url}
                                    alt="Podcast Thumbnail"
                                    style={{ width: '60px', height: '60px' }}
                                    variant="rounded"
                                />
                                </Grid>
                                <Grid item>
                                <Typography variant="body1">{episode.name}</Typography>
                                </Grid>
                            </Grid>
                            </CardContent>
                        </Card>
                        ))}
                    </div>
                    )}

                </>
              )}

              {/* User Comment */}
              {selectedEpisode && (
                <>
                  <Typography variant="h6" gutterBottom style={{ marginTop: '1rem' }}>
                    Add Comment
                  </Typography>
                  <TextField
                    label="Your Comment"
                    variant="outlined"
                    value={userComment}
                    onChange={handleUserCommentChange}
                    fullWidth
                    multiline
                    rows={4}
                    style={{ marginBottom: '1rem' }}
                  />
                </>
              )}

              {/* Submit Button */}
              {selectedEpisode && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!userComment}
                >
                  Post
                </Button>
              )}
            </form>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PostCard;
