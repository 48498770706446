import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import myBackground from '../svgs/blob-scene-haikei3.svg'
import { Link as RouterLink } from 'react-router-dom';
import Navbar from '../compoments/navbar';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, writeBatch, getDoc, doc } from '../firebase';
import { auth, firestore } from '../firebase';
import { sendEmailVerification } from 'firebase/auth';


export default function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const { firstName, lastName, username, email, password, confirmPassword } = formData;
  const [error, setError] = useState(false);
  const [errMessage, setErrorMessage] = useState('');
  const isButtonDisabled = !(firstName && lastName && username.length >= 3 && username.length <= 15 && email && password && confirmPassword && password === confirmPassword);
  const [usernameAvailable, setUsernameAvailable] = useState(false);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage('Passwords do not match.');
      return;
    }

    const usernameDocRef = doc(firestore, 'username', username.toLowerCase());
    const usernameDocSnap = await getDoc(usernameDocRef);
    setUsernameAvailable(!usernameDocSnap.exists());

    if (!usernameDocSnap.exists()) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const profilePicture = `https://ui-avatars.com/api/?name=${firstName.charAt(0)}+${lastName.charAt(0)}&size=150&background=random&rounded=true`;
        const newUser = {
          firstName,
          lastName,
          username,
          email,
          profilePicture,
        };

        const batch = writeBatch(firestore);
        const userDocRef = doc(firestore, 'Users', userCredential.user.uid);

        batch.set(userDocRef, newUser);
        batch.set(usernameDocRef, { uid: userCredential.user.uid });

        await batch.commit();

        await sendEmailVerification(userCredential.user);

        alert('Sign up successful! Please check your email to verify your account.');

        navigate('/signin');
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
      }
    } else {
      setError(true);
      setErrorMessage('Username is not unique. Please try again.');
      return;
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${myBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '100vh',
        width: '100vw',
        overflow: 'auto'
      }}>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={handleSignUp} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={handleInputChange}
                  error={error}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={lastName}
                  onChange={handleInputChange}
                  error={error}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={username}
                  onChange={handleInputChange}
                  error={error}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleInputChange}
                  error={error}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={handleInputChange}
                  error={error}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={handleInputChange}
                  error={error}
                  helperText={errMessage}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isButtonDisabled}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <RouterLink to="/signin" variant="body2" style={{ color: "#fff6af" }}>
                  {"Already have an account? Sign in"}
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
