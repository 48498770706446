import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  Fab,
} from '@mui/material';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import PostCard from './postCard';
import CreateIcon from '@mui/icons-material/Create';
import UserProfile from './UserProfile'

const Wall = () => {
  const [userAttributes, setUserAttributes] = useState({});
  const [user] = useAuthState(auth);
  const [openDialog, setOpenDialog] = useState(false);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userCollection = collection(firestore, 'Users');
          const userRef = doc(userCollection, user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserAttributes(userData);
          } else {
            console.log('User data not found');
          }
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleSpotifyLogin = async () => {
    try {
      // Redirect the user to the Spotify authorization page
      const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
      const redirectUri = `${window.location.origin}/dashboard`;
      const scopes = 'user-read-private user-read-email user-read-playback-state'; // Add more scopes as needed
      const spotifyAuthorizationUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&scope=${encodeURIComponent(scopes)}`;

      window.location.href = spotifyAuthorizationUrl;
    } catch (error) {
      console.log('Error logging in to Spotify:', error);
    }
  };

  useEffect(() => {
    const handleSpotifyAuthorization = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const authorizationCode = urlParams.get('code');
      
        if (authorizationCode) {
          try {
            // Exchange the authorization code for an access token and refresh token
            const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
            const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
            const redirectUri = `${window.location.origin}/dashboard`;
            const requestBody = new URLSearchParams({
              grant_type: 'authorization_code',
              code: authorizationCode,
              redirect_uri: redirectUri,
              client_id: clientId,
              client_secret: clientSecret,
            });
      
            const response = await fetch('https://accounts.spotify.com/api/token', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: requestBody,
            });
      
            if (response.ok) {
              const data = await response.json();
              const { access_token, refresh_token, expires_in } = data;
      
              // Store the access token, refresh token, and expires_in value securely in your application
              // For example, you can store them in Firebase Auth user metadata or a separate database
      
              // Update the user's document in Firestore with the Spotify connection status and tokens
              const userRef = doc(collection(firestore, 'Users'), user.uid);
              await setDoc(
                userRef,
                {
                  spotifyConnected: true,
                  spotifyAccessToken: access_token,
                  spotifyRefreshToken: refresh_token,
                  spotifyTokenExpiration: new Date().getTime() + expires_in * 1000, // Convert expires_in to milliseconds
                },
                { merge: true }
              );
      
              // Update the userAttributes state with the updated user data
              const userDoc = await getDoc(userRef);
              const userData = userDoc.data();
              setUserAttributes(userData);
      
              console.log('Spotify account connected successfully');
      
              // Remove the authorization code from the URL
              window.history.replaceState({}, document.title, window.location.pathname);
            } else {
              console.log('Error exchanging authorization code for tokens:', response.status);
            }
          } catch (error) {
            console.log('Error exchanging authorization code for tokens:', error);
          }
        }
      };
  
      handleSpotifyAuthorization();
    }, [user]);


  useEffect(() => {
    const checkTokenExpiration = async () => {
        try {
          if (user) {
            const userCollection = collection(firestore, 'Users');
            const userRef = doc(userCollection, user.uid);
            const userDoc = await getDoc(userRef);
      
            if (userDoc.exists()) {
              const userData = userDoc.data();
              let { spotifyRefreshToken } = userData;
              const expirationTime = userData.spotifyTokenExpiration;
      
              // Check if the access token has expired
              const currentTime = new Date().getTime();
              if (expirationTime && currentTime > expirationTime) {
                // Access token has expired, refresh it
                const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
                const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
                const refreshToken = spotifyRefreshToken;
      
                const requestBody = new URLSearchParams({
                  grant_type: 'refresh_token',
                  refresh_token: refreshToken,
                  client_id: clientId,
                  client_secret: clientSecret,
                });
      
                const response = await fetch('https://accounts.spotify.com/api/token', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: requestBody,
                });
      
                if (response.ok) {
                  const data = await response.json();
                  const { access_token, refresh_token, expires_in } = data;
                  
                  // Update the user's document in Firestore with the new access token and expiration time
                  await setDoc(
                    userRef,
                    {
                      spotifyAccessToken: access_token,
                      spotifyTokenExpiration: new Date().getTime() + expires_in * 1000, // Convert expires_in to milliseconds
                      spotifyRefreshToken: refresh_token || spotifyRefreshToken, // Use the existing refresh token if the new one is not provided
                    },
                    { merge: true }
                  );
    
                  // Update the userAttributes state with the refreshed access token
                  setUserAttributes(prevState => ({
                    ...prevState,
                    spotifyAccessToken: access_token,
                  }));
    
                  console.log('Access token refreshed successfully');
                } else {
                  console.log('Error refreshing access token:', response.status);
                }
              }
            } else {
              console.log('User data not found');
            }
          }
        } catch (error) {
          console.log('Error checking access token expiration:', error);
        }
      };
    
      checkTokenExpiration();
    }, [user]);


    const handleOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

  
  return (
    <div style={{ marginTop: '100px' }}>
      <Grid container style={{ width: '90%', margin: '0 auto' }} spacing={2}>
        <Grid item xs={12} sm={5} md={3}>
          {/* Column 1 */}
          <Container maxWidth="sm" style={{ height: '80%', padding: '1rem' }}>
            {user && (
              <UserProfile/>
            )}
            {!userAttributes.spotifyConnected && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSpotifyLogin}
                fullWidth
              >
                Connect Spotify
              </Button>
            )}
          </Container>
        </Grid>

        <Grid item xs={12} sm={7} md={9}>
          {/* Column 2 */}
          <Container
            maxWidth="sm"
            style={{ height: '80%', padding: '1rem', position: 'relative' }}
          >
            {/* Make Post Card */}
            <Fab variant="extended" color="primary" onClick={handleOpenDialog} style={{ position: 'fixed', right: '2rem', bottom: '2rem' }}>
              <Typography variant="body2" style={{ marginLeft: '0.5rem' }}>
                Make a Post
              </Typography>
              <CreateIcon />
            </Fab>

            {/* PostCard Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
              <DialogContent>
                <PostCard />
              </DialogContent>            
            </Dialog>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default Wall;
