import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { collection } from 'firebase/firestore';


function FriendSearchDialog({ onClose }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [user] = useAuthState(auth);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchFriends = async () => {
    try {
      const usersRef = collection('users');
      const querySnapshot = await usersRef.where('username', '==', searchQuery).get();
      const results = querySnapshot.docs.map((doc) => doc.data());
      setSearchResults(results);
    } catch (error) {
      console.log('Error searching friends:', error);
    }
  };

  const handleSendRequest = async (friend) => {
    try {
      const friendRequestsRef = collection('friendRequests');
      await friendRequestsRef.add({
        senderId: user.uid,
        receiverId: friend.userId,
        status: 'pending',
        createdAt: new Date(),
      });
  
      console.log('Friend request sent successfully!');
    } catch (error) {
      console.log('Error sending friend request:', error);
    }
  };
  

  const handleClose = () => {
    setSearchQuery('');
    setSearchResults([]);
    onClose();
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>Search Friends</DialogTitle>
      <DialogContent>
        <TextField
          label="Search by Username"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          fullWidth
          autoFocus
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleSearchFriends}>
          Search
        </Button>

        <List>
          {searchResults.map((friend) => (
            <ListItem key={friend.userId}>
              <ListItemText primary={friend.name} secondary={`@${friend.username}`} />
              <Button variant="outlined" onClick={() => handleSendRequest(friend)}>
                Send Request
              </Button>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FriendSearchDialog;
