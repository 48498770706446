import React from 'react';
import Wall from '../compoments/wall';
import Navbar from '../compoments/navbar';

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#000000' }}>
      <Navbar/>
      <Wall />
    </div>
  );
};

export default Dashboard;
