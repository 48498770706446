import React, { useEffect, useState } from 'react';
import { AppBar,Link, Toolbar, Typography, Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import logo from '../svgs/Screenshot 2023-07-05 at 15.46.23-fotor-bg-remover-20230705155111.png'

const Navbar = () => {
  const navigate = useNavigate();
  const [userAttributes, setUserAttributes] = useState({});
  const [user] = useAuthState(auth);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/', { replace: true });
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userCollection = collection(firestore, 'Users');
          const userRef = doc(userCollection, user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserAttributes(userData);
          } else {
            console.log('User data not found');
          }
        } 
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [navigate, user]);

  const handleLogoClick = () => {
    navigate('/');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { profilePicture } = userAttributes;
  const pictureSrc = profilePicture ? String(profilePicture) : null;

  return (
    <AppBar position="fixed">
      <Toolbar>
      <Link href="/dashboard">
            <Box component="img" sx={{ height: 54 }} alt="Logo" src={logo} />
          </Link>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          {user && user.emailVerified && (
            <>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar src={pictureSrc} sx={{ width: 32, height: 32 }}></Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <Button variant="contained" color="secondary" onClick={handleSignOut} fullWidth>
                    Sign Out
                  </Button>
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
