import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { auth } from '../firebase';
import { applyActionCode } from "firebase/auth";
import Navbar from '../compoments/navbar';


const VerifyEmailPage = () => {
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [redirectMessage, setRedirectMessage] = useState('');

  useEffect(() => {
    console.log(auth)
    const searchParams = new URLSearchParams(location.search);
    const oobCode = searchParams.get('oobCode');

    if (!oobCode) {
      setVerificationStatus('error');
      setRedirectMessage('Invalid verification URL.');
      return;
    }

    console.log(typeof oobCode);

    const verifyEmail = async () => {
      try {
        console.log('trying')
        console.log(oobCode)
        await applyActionCode(auth, oobCode);
        console.log('trying 2')
        setVerificationStatus('verified');
        setRedirectMessage('Email verified successfully!');
        // Optional: Delay the redirect to another page (e.g., dashboard, login)
        setTimeout(() => {
          // Redirect the user to another page based on the authentication status
          if (auth.currentUser) {
            // Redirect to the dashboard if the user is signed in
            window.location.href = '/dashboard';
          } else {
            // Redirect to the sign-in page if the user is not signed in
            window.location.href = '/signin';
          }
        }, 3000);
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationStatus('error');
        setRedirectMessage('Error verifying email. Please try again.');
      }
    };

    verifyEmail();
  }, [location.search]);

  return (
    <>
    <Navbar/>
    <Box minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
      {verificationStatus === 'pending' && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
          <Typography variant="body1">Verifying email...</Typography>
        </Box>
      )}

      {verificationStatus === 'verified' && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1">{redirectMessage}</Typography>
        </Box>
      )}

      {verificationStatus === 'error' && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1" color="error">
            {redirectMessage}
          </Typography>
        </Box>
      )}
    </Box>
    </>
  );
};

export default VerifyEmailPage;
