import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuthState } from 'react-firebase-hooks/auth';
import app from './firebase'; // Import your Firebase configuration
import { auth } from "./firebase";
import { grey } from '@mui/material/colors';
import HomePage from './pages/HomePage';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import VerifyEmailPage from './pages/VerifyEmailPage'; // Import the VerifyEmailPage component
import Homepage from './pages/HomePage';
import Layout from './pages/Layout';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'dark' && {
      primary: {      
        main: '#fff6af',
      },
      secondary:{
        main:'#ff6584',
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
  typography: {    
    fontFamily: 'Poppins',  
  },
  
});

const darkModeTheme = createTheme(getDesignTokens('dark'), {
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent', // Set app bar background color to transparent
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent', // Set app bar background color to transparent
          },
        },
      },
    },
  });

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [authenticationChecked, setAuthenticationChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setAuthenticationChecked(true);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (!authenticationChecked || loading) {
    // Render loading state or a spinner while checking authentication status
    return <div></div>;
  }

  return (
    <Router>
      <ThemeProvider theme={darkModeTheme}>
        <Routes>
          {!user && (
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="signin" element={<SignIn />} />
              <Route path="verify" element={<VerifyEmailPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          )}
          {user && user.emailVerified && (
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="signin" element={<Navigate to="/dashboard" replace />} />
              {/*<Route path="*" element={<Navigate to="/dashboard" replace />} />*/}
            </Route>
          )}
          {user && !user.emailVerified && (
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="signin" element={<SignIn />} />
              <Route path="verify" element={<VerifyEmailPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          )}
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
