import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Avatar, Typography, Button, Divider, Box } from '@mui/material';
import FriendSearchDialog from './FriendSearchDialog';
import { doc, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../firebase';


function UserProfileCard() {
  const [userAttributes, setUserAttributes] = useState({});
  const [user] = useAuthState(auth);
  const [isFriendSearchOpen, setIsFriendSearchOpen] = useState(false);
  const [numberOfFriends, setNumberOfFriends] = useState(0);
  const [numberOfFriendRequests, setNumberOfFriendRequests] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userRef = doc(firestore, 'Users', user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserAttributes(userData);
          } else {
            console.log('User data not found');
          }
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchNumberOfFriends = async () => {
      try {
        const friendsSnapshot = await firestore.collection('users').doc(userAttributes.userId).collection('friends').get();
        const count = friendsSnapshot.size;
        setNumberOfFriends(count);
      } catch (error) {
        console.log('Error fetching number of friends:', error);
      }
    };

    const fetchNumberOfFriendRequests = async () => {
      try {
        const requestsSnapshot = await firestore.collection('users').doc(userAttributes.userId).collection('friendRequests').get();
        const count = requestsSnapshot.size;
        setNumberOfFriendRequests(count);
      } catch (error) {
        console.log('Error fetching number of friend requests:', error);
      }
    };

    if (userAttributes.userId) {
      fetchNumberOfFriends();
      fetchNumberOfFriendRequests();
    }
  }, [userAttributes.userId]);

  const handleAddFriendClick = () => {
    setIsFriendSearchOpen(true);
  };

  const handleCloseFriendSearch = () => {
    setIsFriendSearchOpen(false);
  };

  return (
    <Card variant="outlined" sx={{ textAlign: 'center' }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            {/* User Profile Picture */}
            <Avatar src={userAttributes.profilePicture} alt="User Avatar" />
          </Grid>
          <Grid item xs={12}>
            {/* User Details */}
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', letterSpacing: '0.5px', marginTop: 8, marginBottom: 0 }}>
              {`${userAttributes.firstName} ${userAttributes.lastName}`}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ marginBottom: '0.875em' }}>
              {`@${userAttributes.username}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* Friend Statistics */}
            <Divider />
            <Box display="flex">
              <Box p={2} flex="auto">
                <Typography variant="body1" color="textSecondary" sx={{ fontSize: 14, margin: 0 }}>
                  {numberOfFriends} Friends
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: '0 8px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
              />
              <Box p={2} flex="auto">
                <Typography variant="body1" color="textSecondary" sx={{ fontSize: 14, margin: 0 }}>
                  {numberOfFriendRequests} Friend Requests
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {/* Add Friends button */}
            <Button variant="contained" color="primary" onClick={handleAddFriendClick}>
              Add Friends
            </Button>
          </Grid>
        </Grid>

        {/* Friend Search Dialog */}
        {isFriendSearchOpen && <FriendSearchDialog onClose={handleCloseFriendSearch} />}
      </CardContent>
    </Card>
  );
}

export default UserProfileCard;
